import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/env';
import { Address, NewOrderResponse, UserData, NewOrderDetailsResponse, ValidateAddressResponse, SaveOrderAccessories, SaveOrderCompletedStep, SaveOrderDevices, SaveOrderTrackingNumber, ReturnOrderResponse, ReturnOrderDetailsResponse, InventoryResponse, Customer, DeviceStatus, OrderHistoryResponse } from '@custom-types/types';

@Injectable()
export class ApiService {

  constructor(private http: HttpClient) { }

  public login(username: string, password: string): Observable<UserData> {
    const url = `${environment.apiUrl}/tokens`;
    const body = { "userName": username, "password": password };
    return this.http.post<UserData>(url, body);
  }

  public getNewOrders(filterColumn: string = '', filterValue: string = '', limit: number = 5, page: number = 0): Observable<NewOrderResponse> {
    const url = `${environment.apiUrl}/order`;
    const params = new HttpParams().set('filterColumn', filterColumn).set('filterValue', filterValue).set('limit', limit).set('page', page);
    return this.http.get<NewOrderResponse>(url, { params: params });
  }

  public fulfillNewOrder(orderId: string, customerId: number): Observable<any> {
    const url = `${environment.apiUrl}/order/fulfill`;
    const body = { "orderId": orderId, "bbPlatformCustomerId": customerId.toString() };
    return this.http.post<any>(url, body);
  }

  public newOrderDetails(orderId: string): Observable<NewOrderDetailsResponse> {
    const url = `${environment.apiUrl}/order/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.get<NewOrderDetailsResponse>(url, { params: params });
  }

  public acceptOrder(orderId: string): Observable<any> {
    const url = `${environment.apiUrl}/order/accept`;
    const body = { "orderId": orderId };
    return this.http.post<any>(url, body);
  }

  public rejectOrder(orderId: string, reason: string): Observable<any> {
    const url = `${environment.apiUrl}/order/reject`;
    const body = { "orderId": orderId, "reason": reason };
    return this.http.post<any>(url, body);
  }

  public validateAddress(address: Address): Observable<ValidateAddressResponse> {
    const url = `${environment.apiUrl}/validate-address`;
    return this.http.post<any>(url, address);
  }

  public saveOrderDetailsStep(orderId: string, data: SaveOrderCompletedStep): Observable<any> {
    const url = `${environment.apiUrl}/order/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.put<any>(url, data, { params: params });
  }

  public saveOrderDetailsAddress(orderId: string, data: { "address": Address }): Observable<any> {
    const url = `${environment.apiUrl}/order/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.put<any>(url, data, { params: params });
  }

  public saveOrderDetailsAccessories(orderId: string, data: SaveOrderAccessories): Observable<any> {
    const url = `${environment.apiUrl}/order/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.put<any>(url, data, { params: params });
  }

  public saveOrderDetailsDevices(orderId: string, data: SaveOrderDevices): Observable<any> {
    const url = `${environment.apiUrl}/order/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.put<any>(url, data, { params: params });
  }

  public saveOrderDetailsTrackingNumber(orderId: string, data: SaveOrderTrackingNumber): Observable<any> {
    const url = `${environment.apiUrl}/order/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.put<any>(url, data, { params: params });
  }

  public saveOrderLabel(orderId: string, file: File): Observable<any> {
    const url = `${environment.apiUrl}/order/save-label`;

    const formData: FormData = new FormData();
    formData.append('pdfLabel', file);
    formData.append('orderId', orderId);

    return this.http.post<any>(url, formData);
  }

  public getReturnOrders(filterColumn: string = '', filterValue: string = '', limit: number = 5, page: number = 0): Observable<ReturnOrderResponse> {
    const url = `${environment.apiUrl}/return`;
    const params = new HttpParams().set('filterColumn', filterColumn).set('filterValue', filterValue).set('limit', limit).set('page', page);
    return this.http.get<ReturnOrderResponse>(url, { params: params });
  }

  public fulfillReturnOrder(orderId: string, customerId: number): Observable<any> {
    const url = `${environment.apiUrl}/return/fulfill`;
    const body = { "orderId": orderId, "bbPlatformCustomerId": customerId.toString() };
    return this.http.post<any>(url, body);
  }

  public returnOrderDetails(orderId: string): Observable<ReturnOrderDetailsResponse> {
    const url = `${environment.apiUrl}/return/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.get<ReturnOrderDetailsResponse>(url, { params: params });
  }

  public acceptReturn(orderId: string): Observable<any> {
    const url = `${environment.apiUrl}/return/accept`;
    const body = { "orderId": orderId };
    return this.http.post<any>(url, body);
  }

  public rejectReturn(orderId: string, reason: string): Observable<any> {
    const url = `${environment.apiUrl}/return/reject`;
    const body = { "orderId": orderId, "reason": reason };
    return this.http.post<any>(url, body);
  }

  public saveReturnDetailsStep(orderId: string, data: SaveOrderCompletedStep): Observable<any> {
    const url = `${environment.apiUrl}/return/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.put<any>(url, data, { params: params });
  }

  public saveReturnDetailsTrackingNumber(orderId: string, data: SaveOrderTrackingNumber): Observable<any> {
    const url = `${environment.apiUrl}/return/details`;
    const params = new HttpParams().set('orderId', orderId);
    return this.http.put<any>(url, data, { params: params });
  }

  public sendLabel(orderId: string, pdfFile: File): Observable<any> {
    const url = `${environment.apiUrl}/return/send-label`;

    const formData: FormData = new FormData();
    formData.append('pdfLabel', pdfFile);
    formData.append('orderId', orderId);

    return this.http.post<any>(url, formData);
  }

  public uploadInventory(file: File): Observable<any> {
    const url = `${environment.apiUrl}/inventory/upload`;
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(url, formData);
  }

  public uploadReturnOrders(file: File): Observable<any> {
    const url = `${environment.apiUrl}/return/upload`;
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(url, formData);
  }

  public uploadNewOrders(file: File): Observable<any> {
    const url = `${environment.apiUrl}/order/upload`;
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(url, formData);
  }

  public downloadNewOrderReport(): Observable<any> {
    const url = `${environment.apiUrl}/order/download`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json', observe: 'response' });
  }

  public downloadReturnOrderReport(): Observable<any> {
    const url = `${environment.apiUrl}/return/download`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json', observe: 'response' });
  }

  public getInventory(filterColumn: string = '', filterValue: string = '', isInventoryPageSearch?: boolean, limit: number = 5, page: number = 0): Observable<InventoryResponse> {
    const url = `${environment.apiUrl}/inventory`;
    if (isInventoryPageSearch) {
      const params = new HttpParams().set('filterColumn', filterColumn).set('filterValue', filterValue).set('isInventoryPageSearch', true).set('limit', limit).set('page', page);
      return this.http.get<InventoryResponse>(url, { params: params });
    } else {
      const params = new HttpParams().set('filterColumn', filterColumn).set('filterValue', filterValue).set('limit', limit).set('page', page);
      return this.http.get<InventoryResponse>(url, { params: params });
    }
  }

  public getCustomers(): Observable<Array<Customer>> {
    const url = `${environment.apiUrl}/customers/bitbrew`;
    return this.http.get<Array<Customer>>(url);
  }

  public getReturnStatusOrders(filterColumn: string = '', filterValue: string = '', limit: number = 5, page: number = 0): Observable<ReturnOrderResponse> {
    const url = `${environment.apiUrl}/return`;
    const params = new HttpParams().set('filterColumn', filterColumn).set('filterValue', filterValue).set('returnFulfilled', true).set('limit', limit).set('page', page);
    return this.http.get<ReturnOrderResponse>(url, { params: params });
  }

  public updateReturnStatus(orderId: string): Observable<any> {
    const url = `${environment.apiUrl}/return/change-status`;
    const body = { "orderId": orderId };
    return this.http.post<any>(url, body);
  }

  public getReturnOrderDevices(orderId: string, filterColumn: string = '', filterValue: string = '', limit: number = 5, page: number = 0): Observable<any> {
    const url = `${environment.apiUrl}/return/status`;
    const params = new HttpParams().set('orderId', orderId).set('filterColumn', filterColumn).set('filterValue', filterValue);
    return this.http.get<any>(url, { params: params });
  }

  public updateReturnDeviceStatus(devices: Array<DeviceStatus>): Observable<any> {
    const url = `${environment.apiUrl}/return/device/change-status`;
    return this.http.post<any>(url, devices);
  }

  public getOrderHistory(filterColumn: string = '', filterValue: string = ''): Observable<OrderHistoryResponse> {
    const url = `${environment.apiUrl}/order/order-history`;
    const params = new HttpParams().set('filterColumn', filterColumn).set('filterValue', filterValue);
    return this.http.get<OrderHistoryResponse>(url, { params: params });
  }

  public getReturnOrderHistory(filterColumn: string = '', filterValue: string = ''): Observable<OrderHistoryResponse> {
    const url = `${environment.apiUrl}/return/return-history`;
    const params = new HttpParams().set('filterColumn', filterColumn).set('filterValue', filterValue);
    return this.http.get<OrderHistoryResponse>(url, { params: params });
  }

  public downloadOrderHistoryReport(): Observable<any> {
    const url = `${environment.apiUrl}/order/order-history/download`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json', observe: 'response' });
  }

  public downloadReturnOrderHistoryReport(): Observable<any> {
    const url = `${environment.apiUrl}/return/return-history/download`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json', observe: 'response' });
  }

  public deleteInventoryDevice(id: string): Observable<any> {
    const url = `${environment.apiUrl}/inventory/remove`;
    const params = new HttpParams().set('id', id);
    return this.http.delete<any>(url, { params: params });
  }

}